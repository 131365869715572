<template>
  <card>
      <body-card style="margin: 0;border: none;">
          <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('teaGardenPduService.circular') }}</h4>
          </template>
          <template v-slot:body>
              <b-tabs content-class="mt-0" fill>
                <div v-if="loading">
                    <Loading />
                </div>
                <b-overlay v-else>
                    <div class="text-right">
                        <b-button @click="print" variant="primary" class="btn btn-sm mb-2">
                            <i class="ri-printer-line"></i> {{ $t('globalTrans.print') }}
                        </b-button>
                    </div>
                    <div class="p-3" :class="currentLocale === 'en' ? 'font-en' : 'font-bn'" id="printMe">
                        <div class="mb-4" style="display: flex; flex-wrap: wrap;">
                            <div class="" style="width:50%"><b>{{ $t('teaGardenPduService.circular_name') }}</b> : {{ currentLocale === 'en' ?
                                data.circular_title_en : data.circular_title_bn }}</div>
                            <div class="text-right" style="width:50%"><b>{{ $t('teaGardenPduService.application_deadline') }}</b> : {{
                                data.application_deadline | dateFormat }}</div>
                        </div>
                        <div v-html="currentLocale === 'en' ? data.description_en : data.description_bn" class="editor-content">
                        </div>
                    </div>
                    <b-button @click="back" class="ml-3"><i class="ri-arrow-go-back-fill"></i>  {{ $t('teaGardenConfig.back') }}</b-button>
                </b-overlay>
              </b-tabs>
          </template>
      </body-card>
  </card>
</template>
<script>
  import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
  import Loading from '../serviceViewComponents/loading/Details.vue'
  import { circularShow } from '../../api/routes'
  import Vue from 'vue'
  import VueHtmlToPaper from 'vue-html-to-paper'
  Vue.use(VueHtmlToPaper)
  export default {
      name: 'Details',
      components: {
          Loading
      },
      data () {
          return {
              teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
              data: {
                  id: 0,
                  service_id: 0
              },
              pageStyle: {
                  styles: [
                      'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
                      `${teaGardenServiceBaseUrl}/custom.css`
                  ]
              }
          }
      },
      created () {
          this.getAppDetail()
      },
      computed: {
          appId () {
              return this.$route.params.id
          },
          currentLocale () {
              return this.$i18n.locale
          },
          loading () {
              return this.$store.state.commonObj.loading
          }
      },
      methods: {
          print () {
              this.$htmlToPaper('printMe', this.pageStyle)
          },
          back () {
              this.$router.go(-1)
          },
          async getAppDetail () {
              this.$store.dispatch('mutateCommonProperties', { loading: true })
              const result = await RestApi.getData(teaGardenServiceBaseUrl, `${circularShow}/${this.appId}`)
              if (result.success) {
                  this.data = result.data
              }
              this.$store.dispatch('mutateCommonProperties', { loading: false })
          },
          isImage (path) {
              return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(path)
          }
      }
  }
</script>
<style>
.editor-content {
      /* white-space: pre !important; */
  }
</style>
<style scoped>
  .tag {
      font-weight: 600;
      border-left: 4px solid #1c4261;
      padding-left: 6px;
  }
  .download-btn {
      color: green;
      border: 1px solid gray;
      margin-left: 5px;
      font-size: 18px;
      padding: 2px 4px;
  }
  .yes-btn {
      color: green;
      border: 1px solid gray;
      margin-left: 5px;
      font-size: 18px;
      padding: 1px 3px;
  }
  .no-btn {
      color: red;
      border: 1px solid gray;
      margin-left: 5px;
      font-size: 18px;
      padding: 1px 3px;
      margin-right: 33px !important;
  }
  .tag {
      font-weight: 600;
      border-left: 4px solid #1c4261;
      padding-left: 6px;
  }
  .download-btn {
      color: green;
      border: 1px solid gray;
      margin-left: 5px;
      font-size: 18px;
      padding: 2px 4px;
  }
  .yes-btn {
      color: green;
      border: 1px solid gray;
      margin-left: 5px;
      font-size: 18px;
      padding: 1px 3px;
  }
  .no-btn {
      color: red;
      border: 1px solid gray;
      margin-left: 5px;
      font-size: 18px;
      padding: 1px 3px;
      margin-right: 33px !important;
  }

  .stepper-wrapper {
      margin-top: auto;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
  }
  .stepper-item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
  }

  @media (max-width: 768px) {
      font-size: 12px;
  }
  .stepper-item::before {
      position: absolute;
      content: "";
      border-bottom: 2px solid #ccc;
      width: 100%;
      top: 20px;
      left: -50%;
      z-index: 2;
  }

  .stepper-item::after {
      position: absolute;
      content: "";
      border-bottom: 2px solid #ccc;
      width: 100%;
      top: 20px;
      left: 50%;
      z-index: 2;
  }

  .stepper-item .step-counter {
      position: relative;
      z-index: 5;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #ccc;
      margin-bottom: 6px;
      color: white;
  }

  .stepper-item.active {
      font-weight: bold;
  }

  .stepper-item.completed .step-counter {
      background-color: #4bb543;
      color:white;
  }

  .stepper-item.completed::after {
      position: absolute;
      content: "";
      border-bottom: 2px solid #4bb543;
      width: 100%;
      top: 20px;
      left: 50%;
      z-index: 3;
  }

  .stepper-item:first-child::before {
      content: none;
  }
  .stepper-item:last-child::after {
      content: none;
  }
  .step-name {
      color: black;
      font-weight: bold;
      font-size: 11px;
  }
  .tagTwo {
      position: relative;
      display: inline-block;
      border-radius: 6px;
      clip-path: polygon(20px 0px, 100% 0px, 100% 100%, 0% 100%, 0% 20px);
      background: hsl(250deg, 100%, 40%);
      padding: 5px 25px;
      font-weight: 600;
      font-size: 12px;
      color: #FFF;
      transition: clip-path 500ms;
  }
  .tagTwo:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      background: hsl(252deg, 100%, 60%);
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
      border-radius: 0 0 6px 0;
      transition: transform 500ms;
  }
</style>
